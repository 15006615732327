$(function() {
  if ($("body").hasClass("home")) {
    $(function() {
      $(".area_sort-top").on("click", function(event) {
        $(".mainv--area_sort").toggleClass("active");
        $(".area_sort-bottom").slideToggle();
        event.preventDefault();
      });
    });
    jQuery(function() {
      function imgRondom() {
        let randomList = new Array(
          "mainv--img01",
          "mainv--img02",
          "mainv--img03",
          "mainv--img04",
          "mainv--img05",
          "mainv--img06",
          "mainv--img07",
          "mainv--img08"
        );
        let num = Math.floor(Math.random() * randomList.length);
        let classRandom = randomList[num];
        document.getElementById("mainv").classList.add(classRandom);
      }
      window.onload = function() {
        imgRondom();
      };
    });
    var windowWidth = $(window).width();
    var windowSm = 812;
    if (windowWidth <= windowSm) {
      //横幅640px以下のとき（つまりスマホ時）に行う処理を書く
      jQuery(window).on("load scroll", function() {
        var obj_t_pos = jQuery("#sec_concept").offset().top;
        var scr_count = jQuery(document).scrollTop();
        if (scr_count > obj_t_pos) {
          $(".nav_sp").addClass("animateActive");
        } else {
          $(".nav_sp").removeClass("animateActive");
        }
      });
    } else {
      jQuery(window).on("load scroll", function() {
        var obj_t_pos = jQuery("#sec_concept").offset().top;
        var scr_count = jQuery(document).scrollTop();
        if (scr_count > obj_t_pos) {
          $(".nav_pc").addClass("animateActive");
        } else {
          $(".nav_pc").removeClass("animateActive");
        }
      });
    }
  }
});
