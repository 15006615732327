$(function() {
  if ($("body").hasClass("page-template-page-faq")) {
    $(".slide-content_top").on("click", function(event) {
      $(this).toggleClass("active");
      $(this)
        .next()
        .slideToggle();
      event.preventDefault();
    });
  }
});
