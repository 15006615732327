function dropsort() {
  var browser = document.sort_form.sort.value;
  location.href = browser;
}
$(function() {
  var $replaceElem = $(".switch");
  var replaceSp = "_sp.";
  var replacePc = "_pc.";
  var replaceWidth = 480;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());
    $replaceElem.each(function() {
      var $this = $(this);

      if (windowWidth >= replaceWidth) {
        $this.attr("src", $this.attr("src").replace(replaceSp, replacePc));
      } else {
        $this.attr("src", $this.attr("src").replace(replacePc, replaceSp));
      }
    });
  }

  imageSwitch();
  var resizeTimer;
  $(window).on("resize", function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      imageSwitch();
    }, 200);
  });
});

// sp,pc分岐
var windowWidth = $(window).width();
var windowSm = 812;
if (windowWidth <= windowSm) {
  $(function() {
    $(document).on("click", ".fix-area", "#nav-container .bg", function() {
      $("body").toggleClass("hidden");
    });
    $("#mainv .sort_form--top").on("click", function() {
      $(this)
        .parent()
        .toggleClass("active");
      $(this)
        .next()
        .slideToggle();
    });
    $("#mainv .sort_form--bottom li a").click(function() {
      $(".sort_form--bottom").slideToggle();
      $(".c--lang_form").toggleClass("active");
    });
  });
  $(function() {
    $(document).on("click", ".fix-area", "#nav-container .bg", function() {
      $("body").toggleClass("hidden");
    });
    $("#sp_nav-lang").on("click", function() {
      $(this)
        .parent()
        .toggleClass("active");
      $(this)
        .next()
        .slideToggle();
    });
    $("#sp_nav-lang .sort_form--bottom li a").click(function() {
      $(".sort_form--bottom").slideToggle();
      $(".c--lang_form").toggleClass("active");
    });
  });

  jQuery(function() {
    var headerHight = 68; //ヘッダーの高さをpx指定
    //*ページ内リンクの指定
    jQuery("a[href^='#']").click(function() {
      var href = jQuery(this).attr("href");
      var target = jQuery(href == "#" || href == "" ? "body" : href);
      var position = target.offset().top - headerHight;
      jQuery("html, body").animate({ scrollTop: position }, 550, "swing");
      return false;
    });
    //*ページ外リンクの指定*/
    var url = jQuery(location).attr("href");
    if (url.indexOf("?id=") == -1) {
      // ほかの処理
    } else {
      var url_sp = url.split("?id=");
      var hash = "#" + url_sp[url_sp.length - 1];
      var target2 = jQuery(hash);
      var position2 = target2.offset().top - headerHight;
      jQuery("html, body").animate({ scrollTop: position2 }, 550, "swing");
    }
  });
} else {
  $(function() {
    $("#pc-lang .sort_form--top").on("click", function() {
      $(this)
        .parent()
        .toggleClass("active");
      $(this)
        .next()
        .slideToggle();
    });
    $("#pc-lang .sort_form--bottom li a").click(function() {
      $(".sort_form--bottom").slideToggle();
      $(".c--lang_form").toggleClass("active");
    });
  });
}
